import React, { Component } from "react";
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from "components/layout";
import FormTag from 'components/Page/Forms/Form';
import TextField from 'components/Page/Forms/TextField';
import Button from 'components/Theme/Button'
import { Link } from "gatsby"

const Container = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
`;

const FormContainer = styled.div `
  max-width:70rem;
  padding:2rem;
  margin:0 auto;
`;

const Form = styled(FormTag)`
  display:flex;
  flex-direction:column;
`

const FormHeadline = styled.div`
  ${p => p.theme.typography300};
  color:#000;
  z-index:1;
  text-align:center;
  font-size:3rem;
  margin-bottom:2rem;
`;

const FormSubheadline = styled.div`
  font-weight:400;
  color:#000;
  z-index:1;
  text-align:center;
  font-size:1.7rem;
`;


const Terms = styled.div`
  width:100%;
  text-align:center;
  margin:4rem 0;
  font-size:1.2rem;
  color:${p => p.theme.mediumGrey};
`;

class Registration extends Component {

  state = {
    disabled: true
  }
  agreeToTerms = () => {
    this.setState({disabled: !this.state.disabled})
  }

  render() {
    let registration = this.props.data.allContentfulRegistration.edges[0].node

    return (
      <Layout>
        <Container>
          <FormContainer>
            <FormHeadline>{registration.headline}</FormHeadline>
            <FormSubheadline>{registration.subheadline}</FormSubheadline>
            <Form name="Oven Registration" successPage="/">
                <TextField type="text"
                    autocorrect="off"
                    spellcheck="false"
                    autocapitalize="none"
                    name="firstName"
                    required="required"
                    placeholder="First Name" />
                <TextField type="text"
                    autocorrect="off"
                    spellcheck="false"
                    autocapitalize="none"
                    name="lastName"
                    required="false"
                    placeholder="Last Name" />
                <TextField type="email"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="none"
                  name="email"
                  required="required"
                  placeholder="Email Address" />
                <TextField type="text"
                    autocomplete="off"
                    autocorrect="off"
                    spellcheck="false"
                    autocapitalize="none"
                    name="serialNumber"
                    required="true"
                    placeholder="Serial Number" />
                  
                  <Terms>
                    <input type="checkbox" onClick={this.agreeToTerms} /> I Agree to the <Link to="/policy">Terms and Conditions</Link>
                  </Terms>

                  <Button disabled={this.state.disabled}>Submit</Button>
              </Form>
            </FormContainer>
        </Container>    
      </Layout>
    )
  }
}

export default Registration


// language=GraphQL
export const giveawayQuery = graphql`
  query RegistrationQuery {
    allContentfulRegistration {
      edges {
        node {
          headline
          subheadline
        }
      }
    }
  }
`;
