import React, { Component } from 'react'
import TextBox from 'components/Theme/TextBox';

class TextField extends Component {
  render() {
    const { name, placeholder, required, type, value } = this.props

    return (
      <>
        <TextBox  type={type}
                  autocomplete="off"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="none"
                  name={name}
                  required={required}
                  value={value}
                  placeholder={placeholder} />
      </>
    )
  }
}

export default TextField;
